<template>
  <div @click="goToPage()" class="card-container col-4 col-sm-2 my-4">
    <div :class="[{ 'disabled': isDisabled}, 'card bg-transparent']">
      {{ number }}
    </div>
  </div>
</template>

<script>
import { YEAR, MONTH } from '@/constants';

export default {
  props: ['number'],
  computed: {
    isDisabled() {
      return Date.now() < new Date(YEAR, MONTH, this.number).getTime();
    },
  },
  methods: {
    goToPage() {
      if (!this.isDisabled) {
        this.$router.push({ name: 'Day', params: { day: this.number } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  display: flex;
  justify-content: center;

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    color: white;
    font-size: 24pt;
    font-weight: bold;
    border-radius: 10px;
    border: solid 5px white;

    &.disabled {
      background-color: grey;
      opacity: 0.5;
    }
  }
}
</style>
