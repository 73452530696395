<template>
  <div>
    <day-header/>
    <router-view :key="$route.path"/>
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue';

export default {
  components: {
    'day-header': Header,
  },
};
</script>
