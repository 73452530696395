import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    days: Array.from(Array(25).keys()).slice(1).sort(() => Math.random() - 0.5),
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
