import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home.vue';
import Day from '@/views/days/Day.vue';
import Main from '@/views/days/Main.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/days',
    component: Main,
    children: [
      {
        path: '/days/day-with-image',
        name: 'Day',
        component: Day,
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
