<template>
  <title-cmp :with-back="true"/>
</template>

<script>
import Title from '@/components/header/Title.vue';

export default {
  components: {
    'title-cmp': Title,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  color: white;
}
</style>
