<template>
  <div class="jigsaw-container">
    <a v-if="!!jigsaw.fallback" :href="jigsaw.fallback" target="_blank">
      <button type="button" class="btn btn-dark m-4">{{ fallbackText }}</button>
    </a>
    <iframe
      class="col-12"
      allowFullScreen="true"
      :src="jigsaw.url"
      title="Jigsaw Puzzle">Puzzle of the day</iframe>
  </div>
</template>

<script>
export default {
  props: {
    jigsaw: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fallbackText() {
      const receiver = process.env.VUE_APP_RECEIVER;
      return receiver === 'christine'
        ? 'Sollte das Puzzle nicht laden, hier klicken'
        : 'Si le puzzle ne charge pas, tu peux cliquer ici';
    },
  },
};
</script>

<style lang="scss" scoped>
.jigsaw-container {
  text-align: center;

  iframe {
    width: 100%;
    height: 600px;
    max-height: 90vh;
    border: none;
  }
}
</style>
