<template>
  <div class="day-container row">
    <date-of-the-day class="col-12" :day="day" />
    <div class="image-container col-12 mt-3" v-if="displayImage">
      <img class="picture" :src="picturePath" />
    </div>
    <p class="message" v-if="displayMessage">
      {{ this.dayContent.message }}
    </p>
    <a :href="this.dayContent.url" target="_blank" v-if="displayUrl" class="url col-12">{{
      this.dayContent.urlText
    }}</a>
    <jigsaw-cmp class="col-12 mt-3" v-if="displayJigsaw" :jigsaw="dayContent.jigsaw" />
  </div>
</template>

<script>
/* eslint-disable */
import { DAYS_CONFIG_LOUISE, DAYS_CONFIG_CHRISTINE } from "@/constants";

import DateOfTheDay from "@/components/header/DateOfTheDay.vue";
import JigsawFrame from "@/components/JigsawFrame.vue";

export default {
  components: {
    "date-of-the-day": DateOfTheDay,
    "jigsaw-cmp": JigsawFrame,
  },
  props: {
    day: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dayContent() {
      const receiver = process.env.VUE_APP_RECEIVER;
      const configuration = receiver === "christine" ? DAYS_CONFIG_CHRISTINE : DAYS_CONFIG_LOUISE;
      return configuration.find((dayObject) => dayObject.day === this.day);
    },
    displayImage() {
      return !!this.dayContent.picture;
    },
    picturePath() {
      return this.dayContent.picture ? `pictures/${this.dayContent.picture}` : "";
    },
    displayJigsaw() {
      return !!this.dayContent.jigsaw;
    },
    displayMessage() {
      return !!this.dayContent.message;
    },
    displayUrl() {
      return !!this.dayContent.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.day-container {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;

  .message {
    margin: 16px auto;
    font-size: 30px;
  }

  .url {
    text-align: center;
    color: white;
    font-size: 20px;
    margin-top: -12px;
    text-decoration: underline;
  }

  .image-container {
    text-align: center;
    .picture {
      border: 10px solid white;
      max-width: 100%;
      max-height: 60vh;
      text-shadow: 3px 3px black;
    }
  }
}
</style>
