export const MONTH = 11;
export const YEAR = 2023;

export const DAYS_CONFIG_CHRISTINE = [
  {
    day: 1,
    picture: 'christine/Galette.JPG',
    message: 'Am 6. Januar gibt es wieder Galette des Rois. Hier geht es zum Rezept.',
    url: 'https://www.essen-und-trinken.de/rezepte/galette-des-rois-rezept-fuer-franzoesischen-dreikoenigskuchen-13395022.html',
    urlText: 'Zum Rezept',
  },
  {
    day: 2,
    picture: 'christine/Anniversaire-Papa.JPG',
    message: 'Happy Birthday, Papa! Bald ist es wieder soweit. Ob dann auch wieder gesungen wird? 🎶',
  },
  {
    day: 3,
    picture: 'christine/Ski2.JPG',
    message: 'Liegt denn schon Schnee?',
  },
  {
    day: 4,
    picture: 'christine/Sicile.JPG',
    message: 'Buon Natale! - Weihnachten auf italienisch 🇮🇹',
  },
  {
    day: 5,
    picture: 'christine/Kuss.JPG',
    message: 'Wie wäre es mit einem Kuss? Bald ist ja auch wieder Valentinstag.',
  },
  {
    day: 6,
    picture: 'christine/London.jpg',
    message: 'Wie wäre es mit einem Kurztrip nächstes Jahr? London zum Beispiel?',
  },
  {
    day: 7,
    picture: 'christine/Braderie.JPG',
    message: 'Uhh Käse 😍🧀',
  },
  {
    day: 8,
    picture: 'christine/Pilsen.JPG',
    message: 'Heute ist Wochenende. Zeit für ein Pils, oder?',
  },
  {
    day: 9,
    picture: 'christine/Provencal.JPG',
    message: 'Man könnte ja auch mal wieder gut Essen gehen...',
  },
  {
    day: 10,
    picture: 'christine/Cheminee.JPG',
    message: 'Brrr... heute bleiben wir daheim im Warmen.',
  },
  {
    day: 11,
    picture: 'christine/Anniv-Mama.JPG',
    message: 'Dieses Jahr haben wir echt viel hier gefeiert 🥂',
  },
  {
    day: 12,
    picture: 'christine/Prag-Pont.JPG',
    message: 'Schön war es Prag 😀',
  },
  {
    day: 13,
    picture: 'christine/Peindre-Mama.JPG',
    message: 'Heute wird nochmal gearbeitet!',
  },
  {
    day: 14,
    picture: 'christine/Trinken.JPG',
    message: 'Haben wir dieses Jahr eigentlich mehr gearbeitet oder getrunken? 😅',
  },
  {
    day: 15,
    picture: 'christine/Munich3.JPG',
    message: 'Prost zum Wochenende! 🍻',
  },
  {
    day: 16,
    picture: 'christine/Weinbar.JPG',
    message: 'Eine neue Weinbar haben wir dieses Jahr auch entdeckt. Da müssen wir bald wieder hin 🍷',
  },
  {
    day: 17,
    picture: 'christine/Grillen.JPG',
    message: 'Was gibt es eigentlich diese Weihnacht zu essen?',
  },
  {
    day: 18,
    picture: 'christine/Lille-Noel1.JPG',
    message: 'So langsam kommt Weihnachtsstimmung auf 🎄',
  },
  {
    day: 19,
    picture: 'christine/Dick.JPG',
    message: 'Ob wir am 24. wieder so aussehen? 😂',
  },
  {
    day: 20,
    picture: 'christine/Pacs.JPG',
    message: 'Bald sind wir wieder zusammen? ❤️',
  },
  {
    day: 21,
    picture: 'christine/Cabane.JPG',
    message: 'Dieses Jahr haben wir viel geschafft 😮‍💨 - und die Hütte ist auch fertig!',
  },
  {
    day: 22,
    picture: 'christine/Paques.JPG',
    message: 'Übermorgen ist endlich Ostern - ehh - Weihnachten!',
  },
  {
    day: 23,
    picture: 'christine/Biermesse.JPG',
    message: 'Prost zum Wochenende!',
  },
  {
    day: 24,
    picture: 'christine/Lille-Noel2.JPG',
    message: 'Frohe Weihnachten!',
  },
];

export const DAYS_CONFIG_LOUISE = [
  {
    day: 1,
    picture: 'louise/Day1.jpg',
    message: 'Il y a un an, Louise était petite.',
  },
  {
    day: 2,
    picture: 'louise/Day2.jpg',
    message: 'Cette année, elle est plus grande que la tour.',
  },
  {
    day: 3,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly93YWxscGFwZXJzLmNvbS9pbWFnZXMvaGlnaC9mdW5ueS1wZXBwYS1waWctcGljdHVyZXMtNHhoZDVreXkyc3pxOXdsOC53ZWJw&nop=12',
      fallback: 'https://jigex.com/m1rTX',
    },
  },
  {
    day: 4,
    picture: 'louise/Day4.jpg',
    message: "C'était une belle journée avec Gaspard.",
  },
  {
    day: 5,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9jYWNoZS5jb3Ntb3BvbGl0YW4uZnIvZGF0YS9waG90by93MTIwMF9oNjMwX2MxNy81OC9yYWlwb25jZS1sZS1maWxtLXNtYWxsLmpwZw~~&nop=12',
      fallback: 'https://jigex.com/VNcJW',
    },
  },
  {
    day: 6,
    picture: 'louise/Day6.JPG',
    message: "C'est parti pour la rentrée des classes !!!.",
  },
  {
    day: 7,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9jaGVycnkuaW1nLnBtZHN0YXRpYy5uZXQvZml0L2h0dHBzLjNBLjJGLjJGaW1nLjJFb2hteW1hZy4yRWNvbS4yRnMzLjJGZnJvbW0uMkYxMjgwLjJGZGlzbmV5LjJGZGVmYXVsdF8yMDIwLTEyLTA5XzFkZTAwNjdlLTZlMjUtNDdkMi1hNTg2LTg4ODM4YzUwMDc1ZC4yRWpwZWcvMTIwMHg2NzUvcXVhbGl0eS84MC9jZW5kcmlsbG9uLWNlLWRldGFpbC1waHlzaXF1ZS1ldHJhbmdlLWV0LWFsYXJtYW50LXF1ZS10b3V0LWxlLW1vbmRlLXZpZW50LWRlLXJlbWFycXVlci5qcGc~&nop=12',
      fallback: 'https://jigex.com/swSEg',
    },
  },
  {
    day: 8,
    picture: 'louise/Day8.JPG',
    message: 'Une belle journée à la plage.',
  },
  {
    day: 9,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9zdGF0aWMuaGl0ZWsuZnIvaW1nL2FjdHVhbGl0ZS9pbGxfbS8xOTcyNjM2Njg4L2JsYW5jaGVuZWlnZWlsbHVzdHJsaXZlYWN0aW9uLmpwZw~~&nop=12',
      fallback: 'https://jigex.com/fiWrV',
    },
  },
  {
    day: 10,
    picture: 'louise/Day10.JPG',
    message: 'Hmmm trop bon la glace.',
  },
  {
    day: 11,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9zdGF0aWMuaGl0ZWsuZnIvaW1nL2FjdHVhbGl0ZS9pbGxfbS8xOTcyNjM2Njg4L2JsYW5jaGVuZWlnZWlsbHVzdHJsaXZlYWN0aW9uLmpwZw~~&nop=12',
      fallback: 'https://jigex.com/fiWrV',
    },
  },
  {
    day: 12,
    picture: 'louise/Day12.JPG',
    message: "J'ai fabriqué une licorne.",
  },
  {
    day: 13,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pbWctMzEuY2NtMi5uZXQvdTA4LWZzTVBFZ2pKNEhkRnplQjRiZnBiQXk0PS8xMjQweC9zbWFydC83MmZiODk3MWJjMDI0YWE2OTBhODliYWRjMWJjYjc5My9jY21jbXMtaHVnby8xMDU1NzY3Ny5qcGc~&nop=12',
      fallback: 'https://jigex.com/x7FCD',
    },
  },
  {
    day: 14,
    picture: 'louise/Day14.JPG',
    message: "On s'est bien amusé dans le jardin.",
  },
  {
    day: 15,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pbWcuZnJlZXBpay5jb20vdmVjdGV1cnMtbGlicmUvY29sbGVjdGlvbi1nbm9tZXMtbm9lbC1wbGF0cy1kZXNzaW5lcy1tYWluXzIzLTIxNDkxMzM0MjYuanBnP3c9MTgwMCZ0PXN0PTE3MDA1MDc3MTh-ZXhwPTE3MDA1MDgzMTh-aG1hYz1jNDU5MGRiNWM3MTUwNmQ1MDAzNWM4YjdhODg2ZTE1ZTk0YzZkNWY0ZGZhZmM4OTUwNjYzNzJhYzI1MGMzMDMx&nop=12',
      fallback: 'https://jigex.com/b5wsA',
    },
  },
  {
    day: 16,
    picture: 'louise/Day16.JPG',
    message: 'Encore une belle journée au parc !!!',
  },
  {
    day: 17,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pbWcuZnJlZXBpay5jb20vdmVjdGV1cnMtbGlicmUvbnVpdC1oaXZlci1lbm5laWdlZS1wZXJlLW5vZWwtY2hpZW5zLW1pZ25vbnNfMTMwOC04NzM2My5qcGc_dz0xMzgwJnQ9c3Q9MTcwMDUwNzc3N35leHA9MTcwMDUwODM3N35obWFjPTI0YTU5OTRiZDgyNzg3ZWY0MDZmZjAwYWNkMDM0N2FjZjJjODQxYjNjZjc3ZTgxM2U4YzJiZjBmYjNlYzM1MTk~&nop=12',
      fallback: 'https://jigex.com/iAFWq',
    },
  },
  {
    day: 18,
    picture: 'louise/Day18.JPG',
    message: 'Vive les vacances.',
  },
  {
    day: 19,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pbWctMzEuY2NtMi5uZXQvZVBoT1hweTdNbjJvc2FqS0laX3ZjMW5SWVdjPS8xMjQweC9zbWFydC83Mzg2YTUzMDcxZTM0NTQzODE2YTQxMDIyMzc1MzIxZi9jY21jbXMtaHVnby8xMDU1ODI4MS5qcGc~&nop=12',
      fallback: 'https://jigex.com/Pmf5D',
    },
  },
  {
    day: 20,
    picture: 'louise/Day20.JPG',
    message: "J'ai l'air toute sage...",
  },
  {
    day: 21,
    picture: 'louise/Day21.JPG',
    message: '... mais je fais quand même des bétises.',
  },
  {
    day: 22,
    jigsaw: {
      url: 'https://www.youtube.com/embed/LXpXN3hTJ3c?si=ffSlfEuZSXsZgOCl',
    },
  },
  {
    day: 23,
    picture: 'louise/Day23.png',
    message: 'Je suis allée en Allemagne pour la première fois.',
  },
  {
    day: 24,
    jigsaw: {
      url: 'https://www.youtube.com/embed/6Tfco_qHkrs?si=NtVIw3KHKYo5ubyV',
    },
  },
];
