<template>
  <div class="date-of-the-day">{{ dateOfTheDay }}</div>
</template>

<script>
import { YEAR, MONTH } from '@/constants';

export default {
  props: {
    day: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dateOfTheDay() {
      const dateOfTheDay = new Date(YEAR, MONTH, this.day, 0, 0, 0, 0);
      return dateOfTheDay.toLocaleDateString();
    },
  },
};
</script>

<style lang="scss" scoped>
.date-of-the-day {
  color: white;
  font-size: 30px;
  font-weight: bolder;
  justify-content: center;
  display: flex;
  text-shadow: 1px 1px black;
}
</style>
