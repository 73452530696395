<template>
  <div class="home">
    <title-cmp/>
    <div class="row">
      <card v-for="n in days" v-bind:key="n" :number="n"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Card from '@/components/Card.vue';
import Title from '@/components/header/Title.vue';

export default {
  components: {
    card: Card,
    'title-cmp': Title,
  },
  computed: {
    ...mapState({
      days: (state) => state.days,
    }),
  },
};
</script>

<style lang="scss" scoped>
.home {

}
</style>;
